import { TrackedButton } from '@flock/shared-ui'
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Fade from 'react-reveal/Fade'
import ExchangeGraphic from '../../images/721-agents.svg'
import { HOW_IT_WORKS_PATH } from '../../routeConstants'
import SectionLayout from '../SharedComponents/SectionLayout'

type AgentsHowItWorksSectionProps = {
  title?: string
  description?: string
  ctaText?: string
  graphic?: string
}

const AgentsHowItWorksSection = (props: AgentsHowItWorksSectionProps) => {
  const { title, description, ctaText, graphic } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <SectionLayout name="exchange" backgroundColor="trustBlue.main">
      <Grid
        item
        md={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Fade duration={500} distance="5rem" left>
          <Box width="100%">
            <Box
              component="img"
              src={graphic}
              width={isMobile ? 303 : 544}
              height={isMobile ? 291 : 522}
              alt="How Flock works"
            />
          </Box>
        </Fade>
      </Grid>
      {!isMobile && <Grid item md={1} />}
      <Grid container item md={5} display="flex" alignItems="center">
        <Fade duration={500} distance="5rem" bottom>
          <Box
            display="flex"
            flexDirection="column"
            gap={isMobile ? '16px' : '32px'}
          >
            <Typography variant="h1">{title}</Typography>
            <Typography variant="h4">{description}</Typography>
            <Grid item xs={6}>
              <TrackedButton
                variant={isMobile ? 'primary' : 'secondary'}
                to={HOW_IT_WORKS_PATH}
              >
                {ctaText}
              </TrackedButton>
            </Grid>
          </Box>
        </Fade>
      </Grid>
    </SectionLayout>
  )
}

export default AgentsHowItWorksSection

AgentsHowItWorksSection.defaultProps = {
  graphic: ExchangeGraphic,
  title: 'Introducing the 721 exchange.',
  description:
    'Learn more about how Flock uses the 721 exchange to help your client turn their rental property into a fully passive, diversified, low-cost investment.',
  ctaText: 'Learn more',
}
