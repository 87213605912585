import { CheckFilledIcon } from '@flock/shared-ui'
import {
  Box,
  Divider,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React from 'react'
import SectionLayout from '../SharedComponents/SectionLayout'

const Card = styled(Box)({
  borderRadius: '24px',
  padding: '24px',
  boxShadow: '0px 8px 24px 0px #45494D14',
  gap: '16px',
  backgroundColor: '#ffffff',
  display: 'flex',
  flexDirection: 'column',
})

type CardItemProps = {
  text: string
}

const CardItem = (props: CardItemProps) => {
  const { text } = props
  return (
    <Box display="flex" gap="8px" alignItems="flex-start">
      <Box display="flex" width="24px" height="24px">
        <CheckFilledIcon width="24px" height="24px" />
      </Box>
      <Typography variant="p2">{text}</Typography>
    </Box>
  )
}

const AgentBenefitCardsSection = () => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <SectionLayout name="agent-benefit-cards" backgroundColor="green1.main">
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="16px"
      >
        <Typography variant="h1">
          Easier for agents. Better for clients.
        </Typography>
        <Typography variant="p1">
          Flock gives you and your client more benefits and flexibility than
          traditional property transaction strategies.
        </Typography>
      </Grid>
      {!isTablet && <Grid item sm={2} />}
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <Typography variant="p2" fontWeight="bold">
            Easier for agents.
          </Typography>
          <Divider
            sx={{ width: '100%', marginTop: '8px', marginBottom: '8px' }}
          />
          <CardItem text="Earn a full commission" />
          <CardItem text="Close transactions within 2 weeks of receiving a valuation" />
          <CardItem text="Transact on homes that owners would otherwise not sell" />
          <CardItem text="No showings, listings, or coordination with other brokers" />
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <Typography variant="p2" fontWeight="bold">
            Better for clients.
          </Typography>
          <Divider
            sx={{ width: '100%', marginTop: '8px', marginBottom: '8px' }}
          />
          <CardItem text="Avoid the taxes of a traditional sale" />
          <CardItem text="Limit fee exposure to one side of the transaction" />
          <CardItem text="100% passive ownership with reliable income" />
          <CardItem text="Diversify risk and accelerate upside potential" />
        </Card>
      </Grid>
      {!isTablet && <Grid item sm={2} />}
    </SectionLayout>
  )
}

export default AgentBenefitCardsSection
