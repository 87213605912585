import { Box, SvgIconProps, Typography, useTheme } from '@mui/material'
import React from 'react'

export type IconTextDetailProps = {
  icon: React.FC<SvgIconProps>
  iconSize?: string
  title: React.ReactNode
  description: string
  gap?: string
}

const IconTextDetail = (props: IconTextDetailProps) => {
  const { icon, iconSize, title, description, gap } = props
  const Icon = icon
  const theme = useTheme()
  return (
    <Box display="flex" justifyContent="center" gap={gap || '16px'}>
      <Box width="64px">
        <Icon
          color="moneyGreen"
          width={iconSize || '64px'}
          height={iconSize || '64px'}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="16px">
        <Typography
          variant="h3"
          color="moneyGreen.main"
          sx={{
            minHeight: '56px',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
              fontWeight: 900,
              fontSize: '1.5rem',
              lineHeight: '2rem',
            },
          }}
        >
          {title}
        </Typography>
        <Typography variant="p1" color="moneyGreen.main">
          {description}
        </Typography>
      </Box>
    </Box>
  )
}

export default IconTextDetail

IconTextDetail.defaultProps = {
  iconSize: '64px',
  gap: undefined,
}
