import React from 'react'
import AgentBenefitCardsSection from '../components/AgentsComponents/AgentBenefitCardsSection'
import AgentsAcquisitionsSection from '../components/AgentsComponents/AgentsAcquisitionsSection'
import AgentsBenefitsSection from '../components/AgentsComponents/AgentsBenefitsSection'
import AgentsCtaSection from '../components/AgentsComponents/AgentsCtaSection'
import AgentsHeroSection from '../components/AgentsComponents/AgentsHeroSection'
import AgentsHowItWorksSection from '../components/AgentsComponents/AgentsHowItWorksSection'
import AgentsProcessSection from '../components/AgentsComponents/AgentsProcessSection'
import AgentsTestimonialSection from '../components/AgentsComponents/AgentsTestimonialSection'
import PageWrapper from '../components/SharedComponents/PageWrapper'

const pageTitle = 'Flock | Agents'
const pageDescription = `Your clients' rentals. Full commission, quick close.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const Agents = () => (
  <PageWrapper
    title={pageTitle}
    trackingName="agents"
    headerBackground="green1.main"
  >
    <AgentsHeroSection />
    <AgentsHowItWorksSection />
    <AgentsBenefitsSection />
    <AgentsProcessSection />
    <AgentsCtaSection />
    <AgentBenefitCardsSection />
    <AgentsAcquisitionsSection />
    <AgentsTestimonialSection />
  </PageWrapper>
)

export default Agents
