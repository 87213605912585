import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import {
  AnyInputConfig,
  CloseIcon,
  GridForm,
  InputType,
  TrackedIconButton,
  useTracking,
} from '@flock/shared-ui'
import {
  AddressData,
  createBrokerHelper,
  formatCityStateZip,
  UserEventType,
} from '@flock/utils'
import { Box, Modal, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  LandingCompletedLeadSideEffectsDocument,
  LandingCreateBrokerDocument,
  LandingSearchBrokersDocument,
  LandingSearchLeadsDocument,
  LandingUpdateLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'

import { User } from '../types'

type FormResult = {
  fullName: string
  phoneNumber: string
  email: string
  unitNumber?: string
  additionalInfo?: string
}

type AgentLeadGenFormProps = {
  prefillData: { [key: string]: string }
  address: AddressData
  leadUuid: string
  onSubmitDetails: (user: User) => void
  open: boolean
  onClose: () => void
}

const AgentLeadGenForm = (props: AgentLeadGenFormProps) => {
  const { address, prefillData, leadUuid, onSubmitDetails, open, onClose } =
    props

  const { track } = useTracking()
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)

  const [updateLead] = useMutation(LandingUpdateLeadDocument)
  const [completedLeadSideEffects] = useMutation(
    LandingCompletedLeadSideEffectsDocument
  )
  const [createBroker] = useMutation(LandingCreateBrokerDocument)

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const { refetch: searchBrokers } = useQuery(LandingSearchBrokersDocument, {
    skip: true,
  })

  const onSubmit = async (result: FormResult, reset: any) => {
    setLoading(true)
    const { unitNumber, fullName, email, phoneNumber, ...otherResult } = result

    // Search for the broker by email. If it exists, get the UUID.
    // If it does not exist, create a new broker.
    const brokerName = fullName
    const brokerEmail = email
    const brokerPhone = phoneNumber
    let brokerUuid = ''
    try {
      const searchBrokersResult = await searchBrokers({
        input: {
          searchString: email,
        },
      })

      const { data } = searchBrokersResult
      const matchingBrokers = data?.searchBrokers?.brokers || []
      if (!matchingBrokers.length) {
        const brokerData = {
          phoneNumber,
          fullName,
          email,
        }
        const createBrokerResult = await createBrokerHelper(
          brokerData,
          createBroker
        )
        brokerUuid = createBrokerResult.data.createBroker.uuid
      } else {
        const matchingBroker = matchingBrokers[0]
        brokerUuid = matchingBroker.uuid
      }
    } catch (e) {
      console.error('Failed to find or create agent')
    }

    const resultToSubmit: any = {
      ...otherResult,
      isOwner: 'false',
      brokerUuid,
      attribution: 'brokerLandingDirect',
    }

    const { streetNumber, streetAddress, city, state, zipcode } = address

    try {
      await updateLead({
        variables: {
          updateLeadInput: {
            leadUuid,
            fullName: brokerName,
            email: brokerEmail,
            phoneNumber: brokerPhone,
            brokerUuid,
            answers: JSON.stringify(resultToSubmit),

            addressStreet: streetAddress,
            addressCity: city,
            addressState: state,
            addressZip: zipcode,
            addressUnit: unitNumber,
            addressStreetNumber: streetNumber,
          },
        },
        refetchQueries: [LandingSearchLeadsDocument],
      })

      await completedLeadSideEffects({
        variables: {
          completedLeadSideEffectsInput: {
            leadUuid,
            sendWelcomeEmail: false,
          },
        },
      })

      track('agent-lead-gen', {
        type: 'button',
        leadConversion: 'finished',
        actionType: UserEventType.BUTTON_CLICK,
      })

      reset()
      onSubmitDetails({
        fullName,
        email,
        phoneNumber,
      })
      onClose()
    } catch (e) {
      setShowError(true)
    }
    setLoading(false)
  }

  const inputConfigs: AnyInputConfig[] = [
    {
      name: 'prompt',
      type: InputType.CustomComponent,
      props: {
        component: (
          <Box
            display="flex"
            flexDirection="column"
            gap="16"
            pb="16px"
            pt="32px"
          >
            <Typography variant="h3" pb="16px">
              Let us know a few more details about the property.
            </Typography>
            <Typography variant="h4">
              {address?.streetNumber} {address?.streetAddress}
              <br />
              {formatCityStateZip(address)}
            </Typography>
          </Box>
        ),
      },
    },
    {
      name: 'unitNumber',
      type: InputType.Text,
      props: {
        label: 'Unit Number (if applicable)',
      },
    },
    {
      name: 'additionalInfo',
      type: InputType.Text,
      props: {
        type: 'text',
        label: 'Additional Info',
        multiline: true,
        minRows: 4,
        placeholder: 'Enter any additional info about the property here.',
      },
    },
    {
      name: 'fullName',
      type: InputType.Text,
      required: true,
      props: {
        format: 'fullName',
        label: 'Your Full Name',
      },
    },
    {
      name: 'email',
      type: InputType.Text,
      required: true,
      props: {
        format: 'email',
        label: 'Your Email',
      },
    },
    {
      name: 'phoneNumber',
      type: InputType.Text,
      required: true,
      props: {
        format: 'phone',
        label: 'Your Phone Number',
      },
    },
  ]
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="32px"
        sx={{
          maxHeight: '75vh',
          overflowY: 'scroll',
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#ffffff',
          borderRadius: '24px',
          boxShadow: '0px 8px 24px 0px #45494D14',
          p: '32px',

          [theme.breakpoints.down('md')]: {
            top: 0,
            left: 0,
            maxHeight: 'unset',
            height: 'calc(100% - 32px)',
            width: 'calc(100% - 64px)',
            transform: 'unset',
            borderRadius: 'unset',
            pb: '0px',
          },
        }}
      >
        <TrackedIconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: '32px', right: '32px' }}
        >
          <CloseIcon />
        </TrackedIconButton>
        <GridForm
          onSubmit={onSubmit}
          inputConfigs={inputConfigs}
          loading={loading}
          ctaBoxProps={{
            sx: {
              pb: isTablet ? '32px' : 0,
            },
          }}
          gridProps={{
            spacing: 3,
          }}
          formProps={{
            defaultValues: {
              ...prefillData,
            },
          }}
        />
        {showError && (
          <Typography variant="h3" color="error.main">
            An error occurred, please try again.
          </Typography>
        )}
      </Box>
    </Modal>
  )
}

export default AgentLeadGenForm
