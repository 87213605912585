import { TrackedIconButton, useTracking } from '@flock/shared-ui'
import {
  Box,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import Fade from 'react-reveal/Fade'
import { PlayCircle } from '@mui/icons-material'
import Vimeo from 'react-vimeo-embed'
import TestimonialVideo from '../../images/testimonial-video.webp'
import SectionLayout from '../SharedComponents/SectionLayout'

const AgentsTestimonialSection = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [videoOpen, setVideoOpen] = useState(false)

  const { track } = useTracking()

  const openVideo = () => {
    track('open-testimonial-video')
    setVideoOpen(true)
  }

  return (
    <>
      <SectionLayout name="exchange" backgroundColor="moneyGreen.main">
        <Grid
          item
          md={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Fade duration={500} distance="5rem" left>
            <Box width="100%" position="relative">
              <Box
                component="img"
                src={TestimonialVideo}
                onClick={openVideo}
                width={isMobile ? '300px' : '600px'}
                height={isMobile ? '180px' : '360px'}
                alt="Video Testimonials"
                sx={{ cursor: 'pointer' }}
              />
              <TrackedIconButton
                onClick={openVideo}
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '80px',
                }}
              >
                <PlayCircle fontSize="inherit" color="white" />
              </TrackedIconButton>
            </Box>
          </Fade>
        </Grid>
        {!isMobile && <Grid item md={1} />}
        <Grid container item md={5} display="flex" alignItems="center">
          <Fade duration={500} distance="5rem" bottom>
            <Box
              display="flex"
              flexDirection="column"
              gap={isMobile ? '16px' : '32px'}
            >
              <Typography variant="h1" color="#ffffff">
                Don&apos;t just take it from us.
              </Typography>
              <Typography variant="h4" color="#ffffff">
                See why property owners love Flock.
              </Typography>
            </Box>
          </Fade>
        </Grid>
      </SectionLayout>
      <Modal open={videoOpen} onClose={() => setVideoOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 0,
            maxWidth: '100%',
          }}
        >
          <Vimeo
            video="705483547"
            width={isMobile ? '360' : '960'}
            height={isMobile ? '220' : '585'}
            autoplay
          />
        </Box>
      </Modal>
    </>
  )
}

export default AgentsTestimonialSection
