import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const LocationOutlinedIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 48}
      height={height || 48}
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M33.8182 21.0296C33.8182 18.4192 32.7838 15.9158 30.9425 14.07C29.1012 12.2242 26.6039 11.1872 24 11.1872C21.3961 11.1872 18.8988 12.2242 17.0575 14.07C15.2162 15.9158 14.1818 18.4192 14.1818 21.0296C14.1818 25.0671 17.4044 30.3295 24 36.633C30.5956 30.3295 33.8182 25.0671 33.8182 21.0296ZM24 39.6207C15.9993 32.3308 12 26.1323 12 21.0296C12 17.8391 13.2643 14.7794 15.5147 12.5234C17.7652 10.2674 20.8174 9 24 9C27.1826 9 30.2348 10.2674 32.4853 12.5234C34.7357 14.7794 36 17.8391 36 21.0296C36 26.1323 32.0007 32.3308 24 39.6207Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default LocationOutlinedIcon
