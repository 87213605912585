import {
  FieldTimeIcon,
  HouseOutlinedIcon,
  MoneySymbolIcon,
} from '@flock/shared-ui'
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Carousel from '../SharedComponents/Carousel'
import IconTextDetail, {
  IconTextDetailProps,
} from '../SharedComponents/IconTextDetail'
import SectionLayout from '../SharedComponents/SectionLayout'

type AgentsBenefitsSectionProps = {
  benefits?: IconTextDetailProps[]
}

const AgentsBenefitsSection = (props: AgentsBenefitsSectionProps) => {
  const { benefits } = props

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  if (isTablet) {
    return (
      <SectionLayout name="benefits" backgroundColor="green1.main" fullWidth>
        <Grid item xs={12} display="flex" gap="32px">
          <Carousel>
            {benefits?.map((iconTextDetailProps: IconTextDetailProps) => (
              <Box
                display="flex"
                alignItems="center"
                maxWidth="304px"
                margin="auto"
              >
                <IconTextDetail
                  key={iconTextDetailProps.description}
                  {...iconTextDetailProps}
                />
              </Box>
            ))}
          </Carousel>
        </Grid>
      </SectionLayout>
    )
  }

  return (
    <SectionLayout name="benefits" backgroundColor="green1.main">
      {benefits?.map((iconTextDetailProps: IconTextDetailProps) => (
        <Grid
          key={iconTextDetailProps.description}
          item
          xs={4}
          display="flex"
          gap="32px"
        >
          <IconTextDetail
            key={iconTextDetailProps.description}
            {...iconTextDetailProps}
          />
        </Grid>
      ))}
    </SectionLayout>
  )
}

export default AgentsBenefitsSection

AgentsBenefitsSection.defaultProps = {
  benefits: [
    {
      icon: MoneySymbolIcon,
      title: 'Full commission.',
      description: 'Earn commission when your client closes with Flock.',
    },
    {
      icon: HouseOutlinedIcon,
      title: 'Unlock inventory.',
      description: `Transact off-market homes that owners otherwise may not be interested in selling.`,
    },
    {
      icon: FieldTimeIcon,
      title: 'Hassle free close.',
      description:
        'Flock can close within 2 weeks with minimal effort on your end.',
    },
  ],
}
