import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const MoneySymbolIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M31.4659 29.0667C25.4125 27.4933 23.4659 25.8667 23.4659 23.3333C23.4659 20.4267 26.1592 18.4 30.6659 18.4C35.4125 18.4 37.1725 20.6667 37.3325 24H43.2259C43.0392 19.4133 40.2392 15.2 34.6659 13.84V8H26.6659V13.76C21.4925 14.88 17.3325 18.24 17.3325 23.3867C17.3325 29.5467 22.4259 32.6133 29.8659 34.4C36.5325 36 37.8659 38.3467 37.8659 40.8267C37.8659 42.6667 36.5592 45.6 30.6659 45.6C25.1725 45.6 23.0125 43.1467 22.7192 40H16.8525C17.1725 45.84 21.5459 49.12 26.6659 50.2133V56H34.6659V50.2667C39.8659 49.28 43.9992 46.2667 43.9992 40.8C43.9992 33.2267 37.5192 30.64 31.4659 29.0667Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default MoneySymbolIcon
