import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const FieldTimeIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M59.0623 25.75H43.0623C42.7873 25.75 42.5623 25.975 42.5623 26.25V29.25C42.5623 29.525 42.7873 29.75 43.0623 29.75H59.0623C59.3373 29.75 59.5623 29.525 59.5623 29.25V26.25C59.5623 25.975 59.3373 25.75 59.0623 25.75ZM50.6873 34.25H43.0623C42.7873 34.25 42.5623 34.475 42.5623 34.75V37.75C42.5623 38.025 42.7873 38.25 43.0623 38.25H50.6873C50.9623 38.25 51.1873 38.025 51.1873 37.75V34.75C51.1873 34.475 50.9623 34.25 50.6873 34.25ZM29.8311 20.1562H27.1248C26.7373 20.1562 26.4248 20.4688 26.4248 20.8563V36.3563C26.4248 36.5813 26.5311 36.7875 26.7123 36.9188L36.0186 43.7062C36.3311 43.9312 36.7686 43.8688 36.9936 43.5563L38.5998 41.3625V41.3563C38.8248 41.0438 38.7561 40.6062 38.4436 40.3812L30.5248 34.6562V20.8563C30.5311 20.4688 30.2123 20.1562 29.8311 20.1562Z"
        fill={fillStyle || '#2B2E31'}
      />
      <path
        d="M50.3 42.1188H46.6875C46.3375 42.1188 46.0063 42.3 45.8188 42.6C45.025 43.8563 44.1 45.0188 43.0375 46.0813C41.2063 47.9125 39.075 49.35 36.7063 50.35C34.25 51.3875 31.6438 51.9125 28.9563 51.9125C26.2625 51.9125 23.6563 51.3875 21.2063 50.35C18.8375 49.35 16.7063 47.9125 14.875 46.0813C13.0438 44.25 11.6063 42.1188 10.6063 39.75C9.56879 37.3 9.04379 34.6938 9.04379 32.0001C9.04379 29.3063 9.56879 26.7063 10.6063 24.2501C11.6063 21.8813 13.0438 19.7501 14.875 17.9188C16.7063 16.0876 18.8375 14.6501 21.2063 13.6501C23.6563 12.6126 26.2688 12.0876 28.9563 12.0876C31.65 12.0876 34.2563 12.6126 36.7063 13.6501C39.075 14.6501 41.2063 16.0876 43.0375 17.9188C44.1 18.9813 45.025 20.1438 45.8188 21.4001C46.0063 21.7001 46.3375 21.8813 46.6875 21.8813H50.3C50.7313 21.8813 51.0063 21.4313 50.8125 21.0501C46.7375 12.9438 38.475 7.67505 29.25 7.5688C15.7438 7.40005 4.52504 18.4563 4.50004 31.9501C4.47504 45.4688 15.4313 56.4375 28.95 56.4375C38.2938 56.4375 46.6938 51.1501 50.8125 42.9501C51.0063 42.5688 50.725 42.1188 50.3 42.1188Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default FieldTimeIcon
